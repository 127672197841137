import React from "react"

import Layout from "../components/Layout"
import HeroHeader from "../components/HeroHeader"
import SEO from "../components/seo"

const ContatoPage = () => {
  return (
    <Layout>
      <SEO title="Contato" />
      <HeroHeader
        heroMode={"is-dark"}
        heroClass={"is-triangulo"}
        heroSize={"is-small"}
        navActive={"ajuda"}
      >
        <div className="container has-text-centered">
          <div className="block is-uppercase is-titulo-hero">
            <h1 className="title is-3">Olá! Em que podemos lhe ajudar?</h1>
          </div>
        </div>
        <section className="container m-t-lg m-b-lg">
          <div className="box" id="formularioContato"></div>
        </section>
      </HeroHeader>
    </Layout>
  )
}

export default ContatoPage
